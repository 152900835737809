import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import outSideClick from 'directives/outSideClick';
import SearchBar from 'components/SearchBar/SearchBar.vue';
import defaultUserImage from 'common/images/usuario-01.png';
import CartBlock from 'components/CartBlock/CartBlock.vue';
import HeaderMenuSimple from 'components/HeaderMenuSimple';
import NewContentMenu from 'components/Header/NewContentMenu/NewContentMenu.vue';
import AllGiftsMenu from 'components/Header/AllGiftsMenu/AllGiftsMenu.vue';
import GiftIdeasMenu from 'components/Header/GiftIdeasMenu/GiftIdeasMenu.vue';
import TopMenu from 'components/Header/TopMenu/TopMenu.vue';
import OnlyOnBigBox from 'components/Header/OnlyOnBigBox/OnlyOnBigBox.vue';

import GiftsQuery from 'assets/graphql/queries/UserGifts.graphql';
import CategoriesQuery from 'assets/graphql/queries/Categories.graphql';
import occasions from 'assets/graphql/queries/ReasonsOccasions.graphql';
import landingsService from 'services/landings.service';

import useAccount from 'composables/useAccount';

import getLastTld from 'common/services/utils/getLastTld';
import { getCorpoUrl } from 'common/utils/getCorpoUrl';
import onlyOnBigBoxService from 'services/onlyOnBigbox.service';
import CurrentCampaignQuery from 'assets/graphql/queries/CurrentCampaign.graphql';
import NavbarUser from 'components/NavbarUser/NavbarUser.vue';
import NavbarMobileUser from 'components/NavbarMobileUser/NavbarMobileUser.vue';
import NavbarMobileUserOut from 'components/NavbarMobileUserOut/NavbarMobileUserOut.vue';

import PopoverRegalendario from 'components/PopoverRegalendario/PopoverRegalendario.vue';

import useFilters from 'composables/useFilters';
import useIsMobile from 'common/composables/useIsMobile.js';
import useKustomerBotHelpers from 'common/composables/kustomerBotHelpers';
import { getActivitiesCategories } from '../../../services/getCategories.service';

Vue.directive('outSideClick', outSideClick);

@Component({
  apollo: {
    gifts: GiftsQuery,
    occasions,
    allCategories: {
      query: CategoriesQuery,
      update: data => {
        const categoriesMap = new Map();

        data.categories.forEach(category => {
          const { name, ...data } = category;
          categoriesMap.set(name, data);
        });

        return categoriesMap;
      },
    },
    campaign_header: {
      query: CurrentCampaignQuery,
      variables: {
        filters: '{"show_header": true}',
      },
      update: data => data.campaign,
    },
  },
  components: {
    AllGiftsMenu,
    HeaderMenuSimple,
    NewContentMenu,
    GiftIdeasMenu,
    SearchBar,
    CartBlock,
    NavbarUser,
    NavbarMobileUser,
    NavbarMobileUserOut,
    PopoverRegalendario,
    TopMenu,
    OnlyOnBigBox,
  },
  directives: {
    clickOutside: outSideClick.directive,
  },
  props: {
    user: {
      type: Object,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    wishlistItems: {
      type: Array,
      default: () => [],
    },
    cart: {
      type: Object,
      default: () => {},
    },
    cartIsLoading: {
      type: Boolean,
      required: true,
    },
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { onLogout } = useAccount();
    const { handleChangeFilters, clearCurrentFilters } = useFilters();
    const { isDesktop, isMobile } = useIsMobile();
    const { hideKustomer, showKustomer } = useKustomerBotHelpers();

    return {
      onLogout,
      handleChangeFilters,
      clearCurrentFilters,
      hideKustomer,
      showKustomer,
      isDesktop,
      isMobile,
    };
  },
})
export default class BaseHeaderComponent extends Vue {
  gift = '';
  hiddenBanner = false;
  hiddenTravelBanner = false;
  mobileClicked = false;
  mounted = false;
  windowWidth = null;
  currentCountryHost = null;
  openHeaderMobile = false;
  showCartMenu = false;
  littleMenuOpen = false;
  showSticky = true;
  showInformation = '';
  clickOutside = false;
  tld = getLastTld();
  regalendario = {
    step: 0,
    adviceFlag: false,
  };
  selectedMenu = null;
  landingCategories = null;
  onlyOnBigboxContent = [];
  allActivitiesCategories = [];

  async mounted() {
    this.landingCategories = await landingsService.getLandingsCategories();
    this.onlyOnBigboxContent = await onlyOnBigBoxService.getOnlyInBigBox(4, 1);
    this.allActivitiesCategories = await getActivitiesCategories();
  }

  beforeMount() {
    // This never runs in SSR. So mounted is false only in SSR.
    this.mounted = true;
    this.recalculateWidth();
    this.hiddenBanner =
      Boolean(this.campaign) &&
      window.localStorage.getItem('bb_campaignId') === String(this.campaign.id);
    this.hiddenTravelBanner =
      window.localStorage.getItem('bb_show_travelbanner') === false;

    this.$root.$on('showUserLoginIcon', data => {
      this.showUserIcon = data.showUserIcon;
      this.$emit('refetchUser');
    });
  }

  created() {
    window.addEventListener('resize', this.recalculateWidth);
    this.currentCountryHost = this.$store.host;
    this.$root.$on('RegalendarioPopover', step => {
      this.regalendario.step = step;
      this.regalendario.adviceFlag = true;
    });
  }

  get isMexico() {
    return this.tld === 'mx';
  }

  get isPeru() {
    return this.tld === 'pe';
  }

  get isArg() {
    return this.tld === 'ar';
  }

  get newContent() {
    const boxes = this.categories
      .map(category => {
        return category.boxes.filter(box => box.is_new_header);
      })
      .flat();
    const sortedBoxes = boxes.sort(
      (b, c) => b.new_header_order - c.new_header_order,
    );

    return sortedBoxes.slice(0, 5);
  }

  get featuredContent() {
    const boxes = this.categories
      .map(category => {
        return category.boxes.filter(box => box.is_featured);
      })
      .flat();
    const sortedBoxes = boxes.sort(
      (b, c) => b.featured_order - c.featured_order,
    );
    return sortedBoxes.slice(0, 5);
  }

  get isCampaignHeader() {
    return (
      this.campaign_header &&
      this.campaign_header.url &&
      this.campaign_header.header_name
    );
  }

  get menues() {
    const menues = [
      {
        showInHeader: this.isCampaignHeader && !!this.campaign_header,
        name: 'campaignMenu',
        menuLabel: this.campaign_header?.header_name,
        menuLabelTag: 'a',
        menuLabelProps: {
          href: this.campaign_header?.url,
        },
        labelAction: {
          fn: () => {},
          arg: null,
        },
        component: null,
        props: {},
        events: {},
      },
      {
        showInHeader: true,
        name: 'allGiftsMenu',
        menuLabel: 'Regalos',
        menuLabelTag: !this.isDesktop ? 'span' : 'router-link',
        menuLabelProps: {
          to: {
            name: 'products',
            query: { card: 0 },
          },
        },
        labelAction: {
          fn: this.setStoreFilter,
          arg: null,
        },
        component: AllGiftsMenu,
        props: {
          content: this.allCategories,
          seeAllRoute: {
            routeName: 'products',
            routeQuery: {
              card: 0,
            },
          },
        },
        events: {
          closeOpenedMenu: () => this.goBackMobile(),
        },
      },
      {
        showInHeader: true,
        name: 'Activities',
        menuLabel: 'Actividades',
        menuLabelTag: !this.isDesktop ? 'span' : 'router-link',
        menuLabelProps: {
          to: {
            name: 'products',
            query: { card: 1 },
          },
        },
        labelAction: {
          fn: this.setStoreFilter,
          arg: null,
        },
        component: AllGiftsMenu,
        props: {
          content: this.allActivitiesCategories,
          seeAllRoute: {
            routeName: 'products',
            routeQuery: { card: 1 },
          },
          isActivitiesMenu: true,
        },
        events: {
          closeOpenedMenu: () => this.goBackMobile(),
        },
      },
      {
        showInHeader: true,
        name: 'Events',
        menuLabel: 'Eventos',
        menuLabelTag: 'router-link',
        menuLabelProps: {
          to: {
            name: 'new-custom-landing',
            params: { landingSlug: `eventos-${this.tld}` },
          },
        },
        labelAction: {
          fn: () => {},
          arg: null,
        },
        component: null,
        props: {
          seeAllRoute: {
            routeName: null,
            routeQuery: {},
          },
        },
        events: {},
      },
      // {
      //   showInHeader: true,
      //   name: 'newContentMenu',
      //   menuLabel: 'Nuevos ✨ ',
      //   menuLabelTag: !this.isDesktop ? 'span' : 'router-link',
      //   menuLabelProps: {
      //     to: {
      //       name: null,
      //       query: {},
      //     },
      //   },
      //   labelAction: {
      //     fn: () => {},
      //     // fn: this.setStoreFilter,
      //     arg: ['newer', 'orderBy'],
      //   },
      //   component: NewContentMenu,
      //   props: {
      //     content: this.newContent,
      //     seeAllRoute: {
      //       // routeName: 'products',
      //       routeName: null,
      //       routeQuery: {},
      //     },
      //   },
      //   events: {
      //     closeOpenedMenu: () => this.goBackMobile(),
      //   },
      // },
      {
        showInHeader: true,
        name: 'topGifts',
        menuLabel: 'Top 5 🔥',
        menuLabelTag: !this.isDesktop ? 'span' : 'router-link',
        menuLabelProps: {
          to: {
            name: null,
            query: {},
          },
        },
        labelAction: {
          fn: () => {},
          // fn: this.setStoreFilter,
          arg: ['relevance', 'orderBy'],
        },
        component: TopMenu,
        props: {
          content: this.featuredContent,
          seeAllRoute: {
            routeName: null,
            routeQuery: {},
          },
        },
        events: {
          closeOpenedMenu: () => this.goBackMobile(),
        },
      },
      {
        showInHeader: true,
        name: 'onlyBigbox',
        menuLabel: 'Solo en Bigbox 💎',
        menuLabelTag: 'span',
        menuLabelProps: {
          to: {
            name: null,
            query: {},
          },
        },
        labelAction: {
          fn: () => {},
          arg: null,
        },
        component: OnlyOnBigBox,
        props: {
          content: this.onlyOnBigboxContent,
          seeAllRoute: {
            routeName: 'new-custom-landing',
            routeQuery: {},
            routeParams: { landingSlug: `solo-en-bigbox-${this.tld}` },
          },
        },
        events: {
          closeOpenedMenu: () => this.goBackMobile(),
        },
      },
      {
        showInHeader: true,
        name: 'giftIdeas',
        menuLabel: 'Ideas de regalos',
        menuLabelTag: 'span',
        menuLabelProps: {},
        labelAction: {
          fn: () => {},
          arg: null,
        },
        component: GiftIdeasMenu,
        props: {
          content: this.landingCategories,
          seeAllRoute: {
            routeName: null,
            routeQuery: {},
          },
        },
        events: {
          closeOpenedMenu: () => this.goBackMobile(),
        },
      },
    ];

    return menues;
  }

  get countries() {
    return [
      { name: 'ARG', host: this.replaceHostTld('com.ar') },
      { name: 'PE', host: this.replaceHostTld('com.pe') },
      { name: 'CL', host: this.replaceHostTld('cl') },
      { name: 'UY', host: this.replaceHostTld('com.uy') },
      { name: 'MEX', host: this.replaceHostTld('com.mx') },
    ];
  }

  setStoreFilter(filters) {
    if (!this.isDesktop) return;
    if (!filters) this.clearCurrentFilters();
    this.handleChangeFilters(...filters);
  }

  countryChanged() {
    window.location.href = `https://${this.currentCountryHost}`;
  }

  replaceHostTld(tld) {
    const { host } = this.$store;
    return host.replace(
      /^(www\.[^.]+\.)[^:]+(:\d+)?$/,
      (fullMatch, preHost, postHost) => {
        return preHost + tld + (postHost || '');
      },
    );
  }

  get isTravelRoute() {
    const list_travel = ['travel', 'package'];
    return list_travel.indexOf(this.$route.name) >= 0;
  }

  /**
   * Show the user profile image or the default image.
   * @returns {*}
   */
  get userProfileImage() {
    if (this.user) {
      return (
        this.user.profile_image || this.user.facebook_image || defaultUserImage
      );
    }
    return defaultUserImage;
  }

  /**
   * Called from BaseView when route changes
   */
  routeUpdated() {
    this.selectedMenu = null;
    this.mobileClicked = false;
    this.openHeaderMobile = false;
    this.showCartMenu = false;
  }

  toggleHeaderMobile() {
    this.openHeaderMobile = !this.openHeaderMobile;
    if (this.openHeaderMobile) {
      this.hideKustomer();
    } else {
      this.showKustomer();
    }
  }

  openMenu(menu) {
    if (this.selectedMenu && this.selectedMenu.name === menu.name) return;

    if (this.isDesktop) {
      this.selectedMenu = menu;
    }
  }

  /**
   * Deploys the gift menu on click only for mobile view
   */
  openMenuMobile(menu) {
    if (!this.isDesktop) {
      if (!this.selectedMenu) {
        this.selectedMenu = menu;
        this.mobileClicked = true;
      } else if (this.selectedMenu) {
        this.selectedMenu = null;
      }
    }
    this.showSticky = false;
  }

  redirectToCorpo() {
    window.location.href = getCorpoUrl();
  }

  closeMenu(menu) {
    if (this.selectedMenu === menu && this.isDesktop) {
      this.selectedMenu = null;
    }
    this.showSticky = true;
  }

  goBackMobile() {
    this.selectedMenu = null;
    this.showSticky = true;
  }

  /**
   * Restart the initial state to the Hamburger and gift menu
   */
  recalculateWidth() {
    this.windowWidth = window.innerWidth;
    this.mobileClicked = false;
  }

  hideBanner() {
    window.localStorage.setItem('bb_campaignId', String(this.campaign.id));
    this.hiddenBanner = true;
  }

  hideTravelBanner() {
    window.localStorage.setItem('bb_show_travelbanner', false);
    this.hiddenTravelBanner = true;
  }

  get isKairos() {
    return Boolean(this.user) && this.user.is_kairos;
  }

  get catalogUrl() {
    return `/catalogo/${this.gift.signed_code_id}/`;
  }

  toggleCartMenu() {
    this.showCartMenu = !this.showCartMenu;
  }

  closeCartMenu() {
    this.showCartMenu = false;
  }

  refetchUser() {
    this.$emit('refetchUser');
  }

  handleRegalendarioClick() {
    if (!this.user) {
      return this.$router.push({
        name: 'login',
        query: { next: this.currentUrl },
      });
    }

    const {
      isRegalendarioView,
      isHomeView,
      hasReminderDiscount,
      haveReminders,
    } = {
      isRegalendarioView: this.$route.name === 'regalendario',
      isHomeView: this.$route.name === 'home',
      hasReminderDiscount: this.user.has_created_three_reminders,
      haveReminders: Boolean(this.user.reminders.length),
    };

    if (isRegalendarioView) {
      // to avoid duplicated navigation
      return;
    } else if (!isHomeView) {
      return this.redirectToRegalendario();
    } else if (!haveReminders) {
      // if has 0 reminders
      return this.$emit('toggleOnboarding', true);
    } else if (!hasReminderDiscount) {
      // if has 1 or 2 reminders
      return this.$emit('toggleRemindersModalView', true);
    } else {
      // if has more than or equal to 3
      this.redirectToRegalendario();
    }
  }

  redirectToRegalendario() {
    return this.$router.push({ name: 'regalendario' });
  }

  get getHelpUrl() {
    const { tld: countryCode } = this.$store;
    const countries = {
      ar: { helpUrl: 'https://ayuda.bigbox.com.ar' },
      uy: { helpUrl: 'https://ayuda.bigbox.com.uy' },
      pe: { helpUrl: 'https://ayuda.bigbox.com.pe' },
      mx: { helpUrl: 'https://ayuda.bigbox.com.mx' },
      cl: { helpUrl: 'https://ayuda.bigbox.cl' },
    };

    return countries[countryCode]?.helpUrl;
  }

  async logout() {
    const { data } = await this.onLogout();
    const { logout } = data;
    if (!logout) return;
    this.refetchUser();
    this.$router.push({ name: 'login' });
  }

  get showOverlay() {
    const hasSelectedMenu = this.selectedMenu !== null;
    const hasComponent = hasSelectedMenu && !!this.selectedMenu.component;
    return hasComponent;
  }

  @Watch('showOverlay')
  onOpenedMenuChanges(payload) {
    this.$emit('showOverlay', payload);
  }
}
